var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('Spinner') : _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "kiosk-card kiosk-graph"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("Annual Emissions "), _c('small', [_vm._v("(tonnes CO2e)")])]), _c('PieChart', {
    attrs: {
      "options": _vm.annualEmissionsChartData
    }
  })], 1)]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "kiosk-card kiosk-graph"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("Annual Emissions Year On Year "), _c('small', [_vm._v("(tonnes CO2e)")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-9"
  }, [_c('BarChart', {
    attrs: {
      "options": _vm.annualEmissionsHistoricChartData
    }
  })], 1), _c('div', {
    staticClass: "col-md-3 d-flex align-items-center justify-content-end"
  }, [_c('ul', {
    staticClass: "list-unstyled text-white font-size-h2 mb-0"
  }, [_c('li', {
    staticClass: "mb-2 font-size-h2"
  }, [_c('i', {
    staticClass: "fa fa-circle",
    staticStyle: {
      "color": "#4d9575"
    }
  }), _vm._v(" Last 12 Months ")]), _c('li', {
    staticClass: "font-size-h2"
  }, [_c('i', {
    staticClass: "fa fa-circle",
    staticStyle: {
      "color": "#dddddd"
    }
  }), _vm._v(" Previous Period ")])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "kiosk-card kiosk-card--small"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("Supplier Fuel Mix")]), _c('div', {
    staticClass: "border-bottom mb-4 pb-4"
  }, [_c('h2', {
    staticClass: "text-white mb-0"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-leaf text-success"
  }), _vm._v(" Renewable")]), _c('div', {
    staticClass: "kiosk-card--display"
  }, [_vm._v(_vm._s(_vm.renewablePercentage) + "%")])])]), _c('div', [_c('h2', {
    staticClass: "text-white mb-0"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-burn text-warning"
  }), _vm._v(" Non-Renewables")]), _c('div', {
    staticClass: "kiosk-card--display"
  }, [_vm._v(_vm._s(100 - _vm.renewablePercentage) + "%")])])])])]), _c('div', {
    staticClass: "col-md-9"
  }, [_c('div', {
    staticClass: "kiosk-card mb-3"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-9"
  }, [_c('h2', {
    staticClass: "kiosk-card--title border-bottom-0 mb-0 pb-0"
  }, [_vm._v("Air Quality Index")]), _vm.asset.recentAirQuality && _vm.asset.recentAirQuality.index ? _c('p', {
    staticClass: "text-white-75 mb-0 font-size-h5"
  }, [_vm._v(" Last updated on " + _vm._s(_vm._f("date")(_vm.asset.recentAirQuality.lastMeasured, 'DD/MM/YYYY HH:mm')) + " ")]) : _c('p', {
    staticClass: "text-white-75 mb-0"
  }, [_vm._v("No nearby air quality data found")])]), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_vm.asset.recentAirQuality && _vm.asset.recentAirQuality.index ? _c('div', {
    staticClass: "air-quality-rating badge mb-0 text-white",
    style: {
      'background-color': _vm.asset.recentAirQuality.indexColour
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.asset.recentAirQuality.index) + " ")]), _c('span', [_vm._v(_vm._s(_vm.asset.recentAirQuality.indexRating))])]) : _vm._e()])])]), _vm.asset.recentAirQuality && _vm.asset.recentAirQuality.index ? _c('div', [_c('div', {
    staticClass: "row text-center text-white mb-3"
  }, _vm._l(_vm.airQualityData, function (qualityType, code) {
    return _c('div', {
      key: code,
      staticClass: "col-xl-2 col-4",
      attrs: {
        "title": qualityType.description
      }
    }, [_c('div', {
      staticClass: "bg-black-25 p-3"
    }, [_c('h1', {
      staticClass: "kiosk-card--display font-size-h1 mb-0"
    }, [_vm._v(" " + _vm._s(qualityType.name) + " ")]), _c('div', {
      staticClass: "font-size-h2 font-w400"
    }, [_c('strong', {
      style: {
        color: qualityType.colour
      }
    }, [_vm._v(_vm._s(qualityType.value || 'N/A'))]), _c('br')])])]);
  }), 0), _c('div', {
    staticClass: "row text-center text-white"
  }, _vm._l(_vm.weatherData, function (qualityType, code) {
    return _c('div', {
      key: code,
      staticClass: "col-xl-3 col-6",
      attrs: {
        "title": qualityType.description
      }
    }, [_c('div', {
      staticClass: "bg-black-25 p-3"
    }, [_c('h1', {
      staticClass: "kiosk-card--display font-size-h1 mb-0"
    }, [_vm._v(" " + _vm._s(qualityType.name) + " ")]), _c('div', {
      staticClass: "font-size-h2 font-w400"
    }, [_c('strong', {
      style: {
        color: qualityType.colour
      }
    }, [_vm._v(_vm._s(qualityType.value || 'N/A'))])])])]);
  }), 0)]) : _vm._e()])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }