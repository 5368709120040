<template>
  <div>
    <Spinner v-if="loading" />

    <div v-else>
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="kiosk-card kiosk-graph">
            <!-- Energy Consumption Past 7 Days -->
            <h2 class="kiosk-card--title">Annual Emissions <small>(tonnes CO2e)</small></h2>
            <PieChart :options="annualEmissionsChartData" />
          </div>
        </div>
        <div class="col-md-8">
          <div class="kiosk-card kiosk-graph">
            <h2 class="kiosk-card--title">Annual Emissions Year On Year <small>(tonnes CO2e)</small></h2>
            <div class="row">
              <div class="col-md-9">
                <!-- Energy Consumption Past 7 Days -->
                <BarChart :options="annualEmissionsHistoricChartData" />
              </div>
              <div class="col-md-3 d-flex align-items-center justify-content-end">
                <ul class="list-unstyled text-white font-size-h2 mb-0">
                  <li class="mb-2 font-size-h2">
                    <i class="fa fa-circle" style="color: #4d9575"></i>
                    Last 12 Months
                  </li>
                  <li class="font-size-h2">
                    <i class="fa fa-circle" style="color: #dddddd"></i>
                    Previous Period
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="kiosk-card kiosk-card--small">
            <h2 class="kiosk-card--title">Supplier Fuel Mix</h2>
            <div class="border-bottom mb-4 pb-4">
              <h2 class="text-white mb-0">
                <div class="mb-2"><i class="fa fa-fw fa-leaf text-success"></i> Renewable</div>
                <div class="kiosk-card--display">{{ renewablePercentage }}%</div>
              </h2>
            </div>
            <div>
              <h2 class="text-white mb-0">
                <div class="mb-2"><i class="fa fa-fw fa-burn text-warning"></i> Non-Renewables</div>
                <div class="kiosk-card--display">{{ 100 - renewablePercentage }}%</div>
              </h2>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="kiosk-card mb-3">
            <div class="row align-items-center">
              <div class="col-md-9">
                <h2 class="kiosk-card--title border-bottom-0 mb-0 pb-0">Air Quality Index</h2>

                <p v-if="asset.recentAirQuality && asset.recentAirQuality.index" class="text-white-75 mb-0 font-size-h5">
                  Last updated on {{ asset.recentAirQuality.lastMeasured | date('DD/MM/YYYY HH:mm') }}
                </p>

                <p v-else class="text-white-75 mb-0">No nearby air quality data found</p>
              </div>
              <div class="col-md-3 text-right">
                <div
                  v-if="asset.recentAirQuality && asset.recentAirQuality.index"
                  class="air-quality-rating badge mb-0 text-white"
                  :style="{ 'background-color': asset.recentAirQuality.indexColour }"
                >
                  <strong>{{ asset.recentAirQuality.index }} </strong>
                  <span>{{ asset.recentAirQuality.indexRating }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="asset.recentAirQuality && asset.recentAirQuality.index">
            <div class="row text-center text-white mb-3">
              <div v-for="(qualityType, code) in airQualityData" :key="code" class="col-xl-2 col-4" :title="qualityType.description">
                <div class="bg-black-25 p-3">
                  <h1 class="kiosk-card--display font-size-h1 mb-0">
                    {{ qualityType.name }}
                  </h1>
                  <div class="font-size-h2 font-w400">
                    <strong :style="{ color: qualityType.colour }">{{ qualityType.value || 'N/A' }}</strong
                    ><br />
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-center text-white">
              <div v-for="(qualityType, code) in weatherData" :key="code" class="col-xl-3 col-6" :title="qualityType.description">
                <div class="bg-black-25 p-3">
                  <h1 class="kiosk-card--display font-size-h1 mb-0">
                    {{ qualityType.name }}
                  </h1>
                  <div class="font-size-h2 font-w400">
                    <strong :style="{ color: qualityType.colour }">{{ qualityType.value || 'N/A' }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Spinner from '@/components/Spinner';
import BarChart from '@/components/kiosk/BarChart';
import PieChart from '@/components/kiosk/PieChart';

export default {
  name: 'KioskCarbon',
  components: {
    BarChart,
    PieChart,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    slide: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      kiosk: 'kiosk/kiosk',
      asset: 'kiosk/asset',
      accounts: 'kiosk/accounts',
      emissions: 'kiosk/assetEmissions',
      loading: 'kiosk/assetLoading'
    }),
    renewablePercentage() {
      const accountId = this.slide.data?.supplierHighlight?.accountId;
      if (!accountId) return 'N/A';

      const account = this.accounts[this.slide.data?.supplierHighlight?.accountId];
      if (!account) return 'N/A';

      return account.contracts.find(c => c.status === 'active').renewablePercentage;
    },
    airQualityData() {
      const { pm25, pm10, o3, no2, so2, co } = this.asset.recentAirQuality.details;
      return { pm25, pm10, o3, no2, so2, co };
    },
    weatherData() {
      const { t, p, h, w } = this.asset.recentAirQuality.details;
      return { t, p, h, w };
    },
    annualEmissionsChartData() {
      if (!this.emissions.curr) return {};

      return {
        chart: {
          height: '320rem',
          spacingLeft: 10
        },
        series: [
          {
            name: 'Emissions',
            colorByPoint: true,
            data: [
              {
                name: 'Scope 1',
                y: (this.emissions.curr.results.breakdown || []).filter(item => item.scope === '1').reduce((sum, item) => sum + item.total, 0) / 1000
              },
              {
                name: 'Scope 2',
                y: (this.emissions.curr.results.breakdown || []).filter(item => item.scope === '2').reduce((sum, item) => sum + item.total, 0) / 1000
              },
              {
                name: 'Scope 3',
                y: (this.emissions.curr.results.breakdown || []).filter(item => item.scope === '3').reduce((sum, item) => sum + item.total, 0) / 1000
              }
            ]
          }
        ],
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b><br>{point.y:,.2f} tonnes CO2e',
              distance: 50,
              style: {
                fontFamily: 'Inter',
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 'normal',
                textOutline: 'none'
              }
            }
          }
        }
      };
    },
    annualEmissionsHistoricChartData() {
      if (!this.emissions.prev) return {};

      return {
        chart: {
          height: '320rem'
        },
        xAxis: {
          categories: ['Scope 1', 'Scope 2', 'Scope 3'],
          title: {
            text: ''
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        series: [
          {
            name: 'Current Period',
            data: [
              (this.emissions.curr.results?.breakdown || []).filter(item => item.scope === '1').reduce((sum, item) => sum + item.total, 0) / 1000,
              (this.emissions.curr.results?.breakdown || []).filter(item => item.scope === '2').reduce((sum, item) => sum + item.total, 0) / 1000,
              (this.emissions.curr.results?.breakdown || []).filter(item => item.scope === '3').reduce((sum, item) => sum + item.total, 0) / 1000
            ]
          },
          {
            name: 'Previous Period',
            data: [
              (this.emissions.prev.results?.breakdown || []).filter(item => item.scope === '1').reduce((sum, item) => sum + item.total, 0) / 1000,
              (this.emissions.prev.results?.breakdown || []).filter(item => item.scope === '2').reduce((sum, item) => sum + item.total, 0) / 1000,
              (this.emissions.prev.results?.breakdown || []).filter(item => item.scope === '3').reduce((sum, item) => sum + item.total, 0) / 1000
            ]
          }
        ]
      };
    }
  }
};
</script>
<style lang="scss">
.air-quality-rating {
  font-size: 3rem;
  line-height: 54px;
}
</style>
